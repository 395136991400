import React from 'react';

const Paper = ({ children }) => (
  <div className="container">
    <div className="row">
      <div className="col-md-8 offset-md-2">
        <div className="paper paper-page">
          {children}
        </div>
      </div>
    </div>
  </div>
);

export default Paper;
